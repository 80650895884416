<template>
  <div class="editprofile">
    <left-side-bar/>
    <!--  editprofile section-->
    <section class="editprofile-section">
      <top-navbar/>
      <p>Your Profile</p>
      <p>Customize your profile with an avatar, handle, and full name.</p>

      <span v-tooltip.bottom-start="tooltip">AVATAR
        <v-icon>mdi-information</v-icon>
      </span>

      <div class="profile-image">
        <label for="imgFile">
          <div class="profile-image-  upload">
            <img :src="image" alt="profile-img-upload" width="200"/>
            <div>
              <p>Click or drag file to this area to upload</p>
              <p>We support JPEG, PNG, and GIF. When uploading an NFT, please only use one that you personally own.</p>
            </div>
          </div>
          <p>{{ imageFileName }}</p>
        </label>
        <input @change="upload" accept="image/*" type="file" id="imgFile" class="input-img-file">
      </div>

      <div class="profile-about">
        <p>ABOUT</p>
        <div class="input-class">
          <label for="name">Name</label>
          <input type="text" id="name" placeholder="">
          <label for="handle">Handle</label>
          <input type="text" id="handle" placeholder="">
          <label for="description">Description</label>
          <textarea id="description"></textarea>
          <button type="submit">SAVE</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LeftSideBar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import axios from "axios";

export default {
  name: 'EditProfile',
  components: {
    TopNavbar,
    LeftSideBar,
  },
  data() {
    return {
      image: require("@/assets/images/profile/profile-black.jpg"),
      imageFileName: '',
      tooltip: 'We support JPEG, PNG, and GIF. Recommended dimensions for profile images are 400x600 pixels.\n' +
          '\n' +
          '\n' +
          'When uploading an NFT, only use those personally owned.',
    }
  },
  created() {
    this.lineBreaking()
  },
  methods: {
    async upload(e) {
      if (e.target.files.length !== 0) {
        let file = e.target.files;
        this.imageFileName = file[0].name
        this.image = URL.createObjectURL(file[0])
        await axios.post('//13.125.234.124:3002/upload', {
              image: this.image,
            })
            .then(res => {
              this.$store.commit("app/SET_USER_AVATAR", res.data.userImage);
                }
            )
            .catch
            ((err) => {
              console.log(err)
            })
      }
    },
    lineBreaking() {
      this.tooltip = this.tooltip.replace(/(\n|\r\n)/g, '<br>')
    },
    // uploadImg() {
    //   this.$http
    //       .post('//13.125.234.124:3002/upload', {
    //         image: this.image,
    //       })
    //       .then(res => {
    //             console.log(res.data)
    //           }
    //       )
    //       .catch
    //       ((err) => {
    //         console.log(err)
    //       })
    // },
  }
}
</script>